<script>
  import {
    intervensionMethods,
    intervensionGetters,
  } from "@/state/helpers";
  import * as XLSX from 'xlsx'
  import { mapGetters,mapActions } from "vuex";
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  // import axios from 'axios';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import { format } from 'date-fns'
  import MultiRangeSlider from "multi-range-slider-vue";
  export default {
    components: {
      Layout,
      PageHeader,
      DatePicker,
      MultiRangeSlider
    },
    data() {
      return {
        filterFrom: '',
        filterTo: '',
        filter: '',
        interventionIssue:'all',
        selected_product:'all',
        selected: '',
        variation_loader:false,
        variation:'all',
        per_page : 10,
        barMinValue: 25,
        barMaxValue: 100,
        sBarMinValue:25,
        sBarMaxValue : 100, 
        options: [
          { value: 'all', text: 'All' },
          { value: 'bmi_issue', text: 'BMI Verification' },
          { value: 'address_issue', text: 'Address Verification' },
          { value: 'medication_issue', text: 'Medication Verification' },
          { value: 'other', text: 'Other' },
        ],
        fields: [
          { key: "post_title", label: "Order ID" },
          { key: "date" },
          { key: "first_name", label: "First Name" },
          { key: "last_name", label: "Last Name" },
          { key: "age_checked", label: "Age Checked" },
          { key: "status", label: "Status" },
          { key: "payment_status", label: "Payment Status" },
          { key: "order_total", label: "Total" },
          { key: "action" }
        ],
        time1: null,
      }
    },
    created() {
      let options = {
        filterFrom : this.filterFrom,
        filterTo:this.filterTo,
        interventionIssue:this.interventionIssue,
        filter : this.filter, 
        per_page : this.per_page,
        searchBy : this.selected,
        product : this.selected_product,
        bmi_from : this.sBarMinValue,
        bmi_to : this.sBarMaxValue,
        variation : this.variation
      }
      this.getInterventionOrders(options);
    },
    computed: {
      ...intervensionGetters,
      ...mapGetters('global', ['productsFilterOptions','productVariationOptions'])
    },
    methods: {
      ...intervensionMethods,
      ...mapActions('global',[
       'changePrductVariations',
       ]),
       handleIssue(){
        let options = {
        filterFrom : this.filterFrom,
        filterTo:this.filterTo,
        interventionIssue:this.interventionIssue,
        filter : this.filter, 
        per_page : this.per_page,
        searchBy : this.selected,
        product : this.selected_product,
        bmi_from : this.sBarMinValue,
        bmi_to : this.sBarMaxValue,
        variation : this.variation
        }
        this.getInterventionOrders(options);
      },
      handleDates(event) {
        this.filterFrom = format(new Date(event[0]), 'yyyy-MM-dd');
        this.filterTo = format(new Date(event[1]), 'yyyy-MM-dd');
        let options = {
        filterFrom : this.filterFrom,
        filterTo:this.filterTo,
        interventionIssue:this.interventionIssue,
        filter : this.filter, 
        per_page : this.per_page,
        searchBy : this.selected,
        product : this.selected_product,
        bmi_from : this.sBarMinValue,
        bmi_to : this.sBarMaxValue,
        variation : this.variation
      }
        this.getInterventionOrders(options);
      },
      resetDatesFilter(){
        let options = { 
          filterFrom : '',
          filterTo:'',
          interventionIssue:this.interventionIssue,
          filter : this.filter, 
          per_page : this.per_page,
          searchBy : this.selected,
          product : this.selected_product,
          bmi_from : this.sBarMinValue,
          bmi_to : this.sBarMaxValue,
          variation : this.variation
        }
        this.getInterventionOrders(options);
      },
      handleFilterSearch(){
        let options = {
          filterFrom : this.filterFrom,
          filterTo:this.filterTo,
          interventionIssue:this.interventionIssue,
          filter : this.filter, 
          per_page : this.per_page,
          searchBy : this.selected,
          product : this.selected_product,
          bmi_from : this.sBarMinValue,
          bmi_to : this.sBarMaxValue,
          variation : this.variation
        }
        this.getInterventionOrders(options);
    },
    capitalizeFirstLetter(string) {
       return string.charAt(0).toUpperCase() + string.slice(1);
    },
    resetSearchFilter(){
      this.sBarMinValue = 25;
      this.sBarMaxValue = 100;
      let options = {
          filterFrom : '',
          filterTo:'',
          interventionIssue:'all',
          filter : '', 
          per_page : 10,
          searchBy : '',
          product : '',
          bmi_from : this.sBarMinValue,
          bmi_to : this.sBarMaxValue,
          variation : ''
        }
      this.getInterventionOrders(options); 
    },
    download : function() {
      let exportData = [];
    this.orders.forEach((item) => {
      let variations = '';
      if(item.product_detail.variation){
        item.product_detail.variation.forEach(innerItem => {
          variations += innerItem.variation_name+' , '+innerItem.name+ ' , '+innerItem.price+ (innerItem.quantity ? ' , ' +innerItem.quantity+' , ' : ' ,');
        })
      }
      let orderData = { 
         "Order No" : item.post_title,
         "Order Date" : item.date,
         "First Name" : item.first_name,
         "Last Name" : item.last_name,
         "Email" : item.email,
         "Product" : item.product,
         "Variation" : variations,
         "Status" : item.status,
         "Age Checked" : item.age_checked,
         "Payment Status" : item.payment_status,
         "Order Total" : item.order_total,
       }
       exportData.push(orderData);
      })
      var wscols = [{wch:15},{wch:20},{wch:20},{wch:20},{wch:35},{wch:35},{wch:45},{wch:20},{wch:20},{wch:20},{wch:20}];
      const data = XLSX.utils.json_to_sheet(exportData)
      data['!cols'] = wscols;
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      XLSX.writeFile(wb,'interventions.xlsx');
      },
    update_sBarValues(e) {
      this.sBarMinValue = e.minValue;
      this.sBarMaxValue = e.maxValue;
    }
  },
   mounted(){
  
    }
  }
  </script> 
<template>
    <Layout>
      <PageHeader :title="'INTERVENTION ORDERS:'" />
      <b-card>
        <b-row class="align-items-center">
          <b-col cols="12" md="8">
            <b-row class="align-items-center mb-3">
              <b-col cols="2" class="pr-md-0">Filter By Issue</b-col>
              <b-col cols="6" class="pl-md-0"><b-form-select v-model="interventionIssue" :options="options" @change="handleIssue()"></b-form-select></b-col>
            </b-row>
          </b-col>
          <b-col cols="12" md="4" class="mb-3 d-flex align-items-center date-picker">
              <date-picker v-model="time1" range @confirm="handleDates($event)" @clear="resetDatesFilter" :confirm="true"
              confirm-text="Filter" :show-second="false" placeholder="Filter products from specific dates"></date-picker>
              <b-button variant="outline-primary" class="py-1 ml-3 w-25 d-flex" v-b-toggle.sidebar-right3><i class="mdi mdi-filter"></i> Filters</b-button>
              <b-button variant="outline-primary" class="py-1 ml-3 w-25 d-flex" v-on:click="download"><i class="mdi mdi-file-chart"></i> Export</b-button>
          </b-col>

        </b-row>
  
        <b-table v-if="orders" striped :busy="loader" :items="orders" :fields="fields" show-empty>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Loading...</strong>
            </div>
          </template>

          <template #empty>
              <h5 class="text-center pt-2">No Intervension Orders Found.</h5>
          </template>
          
          <template v-slot:cell(post_title)="row">
            #{{ capitalizeFirstLetter(row.value) }}
          </template>
  
          <template v-slot:cell(order_total)="row">
            £ {{ capitalizeFirstLetter(row.value) }}
          </template>
  
          <template v-slot:cell(action)="row">
            <router-link :to="'/order/' + row.item.ID" class="mr-3 text-success" v-b-tooltip.hover title="View">
              <i class="mdi mdi-eye font-size-18"></i>
            </router-link>
          </template>
          <template v-slot:cell(payment_status)="row">
            <div class="badge font-size-12" :class="{
              'badge-soft-danger': `${row.value}` === 'Chargeback',
              'badge-soft-success': `${row.value}` === 'successful',
              'badge-soft-warning': `${row.value}` === 'Unpaid'
            }">{{ capitalizeFirstLetter(row.value) }}</div>
          </template>
          <template v-slot:cell(age_checked)="row">
            <div class="badge font-size-12" :class="{
              'badge-soft-danger': `${row.value}` === 'unverified',
              'badge-soft-success': `${row.value}` === 'verified',
              'badge-soft-warning': `${row.value}` === 'not_attempt'
            }">{{ capitalizeFirstLetter(row.value) }}</div>
          </template>
        </b-table>
      </b-card>
  <div>
    <b-sidebar id="sidebar-right3" right shadow bg-variant="light" backdrop backdrop-variant="transparent">
      <b-form class="px-3 py-4" @submit.prevent="handleFilterSearch()" @reset="resetSearchFilter()">
        <h5>Filtering & Sorting</h5>
        <hr class="mt-3 mb-4"/>
        <b-form-group>
           <b-form-radio v-model="selected" class="card-title" value="order_id">Order ID</b-form-radio>
        </b-form-group>
        <b-form-group>
           <b-form-radio v-model="selected"  class="card-title" value="email">Email</b-form-radio>
        </b-form-group>
        <b-form-group>
           <b-form-radio v-model="selected" class="card-title" value="first_name">First Name</b-form-radio>
        </b-form-group>
        <b-form-group>
           <b-form-radio v-model="selected" class="card-title" value="last_name">Last Name</b-form-radio>
        </b-form-group>
        <b-form-group>
           <b-form-radio v-model="selected" class="card-title" value="phone_number">Phone Number</b-form-radio>
        </b-form-group>
        <b-form-group>
           <b-form-radio v-model="selected" class="card-title" value="postcode">Post Code</b-form-radio>
        </b-form-group>

        <b-form-group id="input-group-2" class="card-title mb-3" 
          :label="selected == 'first_name' ? 'Enter First Name:' :  
          selected == 'order_id' ? 'Enter Order ID:' : 
          selected == 'email' ?  'Enter Email:' :  
          selected == 'last_name' ?  'Enter Last Name:' :
          selected == 'postcode' ?  'Enter Post Code:' :
          'Enter Phone Number:'" 
          label-for="input-2">
          <b-form-input
            id="input-2"
            :type="selected == 'email' ? 'email' : 'search'"
            v-model="filter"
            :required="selected ? true : false"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" class="card-title mb-3 range-slider"  label="Select BMI range">
        <MultiRangeSlider
          :min="25"
          :max="100"
          :min-value="sBarMinValue"
          :max-value="sBarMaxValue"
          :step="0.25"
          :ruler="false"
          :range-margin="1"
          @input="update_sBarValues"
        />
       </b-form-group>
        <b-form-group label="Select Product">
          <b-form-select v-if="productsFilterOptions" v-model="selected_product" @change="changePrductVariations($event)" :options="productsFilterOptions"></b-form-select>
        </b-form-group>
        <b-form-group >
          <div class="d-flex align-items-center">
           <label>Select Variation:</label>
           <b-spinner variant="success" v-if="variation_loader" class="d-inline-block ml-2 mb-2" small label="Small Spinner" type="grow"></b-spinner>
          </div>
          <b-form-select v-model="variation" :options="productVariationOptions"></b-form-select>
        </b-form-group>
      <b-button variant="success" class="mr-2" type="submit">
          <i class="ri-check-line align-middle mr-2"></i> Submit
      </b-button>
      <b-button variant="danger" type="reset">
          <i class="ri-close-line align-middle mr-2"></i> Reset
      </b-button>
      </b-form>
    </b-sidebar>
  </div>
  </Layout>
  </template>
<style scoped>
  .date-picker >>> input{
     height:40px
  }
  .range-slider >>> .multi-range-slider{
    border: unset;
    box-shadow: unset;
    padding: 15px 2px;
  }
  .range-slider >>> .multi-range-slider .bar-inner {
    border: unset;
    box-shadow: unset;
    background-color: #1cbb8c;
}
.range-slider >>> .multi-range-slider .thumb::before {
    background-color: white;
    border: solid 2px #ff3d60;
    box-shadow: unset;
}
.range-slider >>> .multi-range-slider .thumb .caption * {
    background-color: white;
    color: black;
    box-shadow: unset;
}
</style>
